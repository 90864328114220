// extracted by mini-css-extract-plugin
export var wrapper = "StoreCard-module--wrapper--FjRH3";
export var overlay = "StoreCard-module--overlay--fZDBn";
export var viewDetails = "StoreCard-module--viewDetails--oTj62";
export var imageWrapper = "StoreCard-module--imageWrapper--Fdope";
export var titleWrapper = "StoreCard-module--titleWrapper--ld7qy";
export var flex = "StoreCard-module--flex--XQF+f";
export var distance = "StoreCard-module--distance--G8Zmp";
export var desc = "StoreCard-module--desc--V3cXw";
export var title = "StoreCard-module--title--lBmfm";
export var withIconWrapper = "StoreCard-module--withIconWrapper---Jd58";
export var withIcon = "StoreCard-module--withIcon--d0UEi";
export var phone = "StoreCard-module--phone--hw6Bs";
export var storeItemLocationIcon = "StoreCard-module--storeItemLocationIcon--g87Xq";