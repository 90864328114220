import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import SearchHelper from "../AC-Header/SearchHelper";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

const SliderMobile = () => {

  // const data = useStaticQuery(graphql`
  //   query {
  //     img2: file(relativePath: { eq: "slider/slider2_en.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1600) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //   }
  // `);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  return (
    <>
      <div id="homeBanner__mobile">
        <div className="homeBanner-title__mobile">
          Introducing World's <span>first</span> marketplace for authenticated sustainable products.
        </div>
        <div className="homeBanner-desc__mobile">
          With DIBIZ buyers can now know the materials are originating from with authenticated records. Discover range of products and their product information. Place your order online today.
        </div>
        {/* <button className="homeBanner-button__mobile">Register for free</button> */}
        {/* <SearchHelper />  */}
      </div>
    </>
  );
};

export default SliderMobile;
