import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Img from "gatsby-image";
import SearchHelper from "../AC-Header/SearchHelper";

import { I18nContext } from "@/i18n/index";
// import sdfsdf from "../header/SearchHelper";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Slider = () => {
  const data = useStaticQuery(graphql`
    query {
      img2: file(relativePath: { eq: "slider/slider2_en.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const { langCode } = React.useContext(I18nContext);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  return (
    <>
      <div id="homeBanner">
        <div className="homeBanner-title">
          Introducing <span>trusted</span> marketplace for certified palm oil products.
        </div>
        <div className="homeBanner-desc">
          With DIBIZ buyers can now know the materials are originating from with authenticated records. Discover range of products and their product information. Place your order online today.
        </div>
        {/* <button className="homeBanner-button">Register for free</button> */}
        <SearchHelper/>
      </div>

      {/*<AutoPlaySwipeableViews
        axis={"x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {tutorialSteps.map((step, index) => (
          <div key={index}>
            <div className="sliderBtns">
              <p>Your  Curbside Pickup  Marketplace</p>
              <Link to={PREVIEW + "/farmers-markets"}>
                Farmer's Markets
              </Link>
              <Link to={PREVIEW + "/shop/retail-store"}>Retail Stores</Link>
              <Link to={PREVIEW + "/shop/restaurants"}>Restaurants</Link>
              <Link to={PREVIEW + "/shop/services"}>Services</Link>
            </div>
            {Math.abs(activeStep - index) <= 2 ? (
              <img
                className="BannerDeskMobile"
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton=""
        backButton=""
        style={{
          transform: "translateY(-30px)",
          position: "absolute",
          background: "transparent",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          width: "100%"
        }}
      />*/}
    </>
  );
};

export default Slider;
