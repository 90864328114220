import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "gatsby";

import { navigate } from "@reach/router";

import * as classes from "./StaticSectionTwoV2.module.css";


const StaticSectionOne = () => {


    const navCatsState = useSelector(
        state => state.menuReducer.navCats,
        shallowEqual
    );

    const isMobileState = useSelector(
        state => state.mainReducer.isMobile,
        shallowEqual
      );

    const loginNameState = useSelector(
        state => state.loginReducer.loginName,
        shallowEqual
    );

    const handleLogin = () => {
        document.getElementById("login-icon-btn").click();
    };

    const handleSignup = ()=>{
        navigate("https://app.dibizglobal.com/signup")
    }

    return (
        <div className={classes.wrapper}>
            <h3 className={classes.title}><span>Equal benefits</span> for Buyers and Suppliers</h3>
            <p className={classes.desc}>Are you finding it hard to find and source authentic certified and sustainable products? DIBIZ is the right place to start with.<br/> Here, you can find verified suppliers and buyers on a global scale and start interacting with them. Reach out to us for more information.</p>
            <div className={classes.section_one}>
                <div className={classes.section_one__one} >
                    <img src="https://ik.imagekit.io/ofb/dbz/illustration_home_6yHjxDDFB.png" alt="Image" />
                </div>
                <div className={classes.section_one__two}>
                    <h3>DIBIZ for <span> {" "} Buyers</span> </h3>
                    <div className={classes.desc__one}>Find and trade your products on one marketplace</div>
                    <p className={classes.desc__two}>Tired of searching for certified sustainable products that cannot give you a guaranteed traceability. DIBIZ offers a new way to search and identify suppliers and connect them with ideal buyers.</p>
                    <ul className={classes.desc__three}>
                        <li>Find best certified products and organizations </li>
                        <li>Brief understanding of the company before reaching out to them </li>
                        <li>Reach out to them, discuss, and negotiate </li>
                        <li>Trade through DIBIZ (optional)</li>
                        <li>Access to global and regional markets</li>
                    </ul>
                    {
                        loginNameState === "" && (
                            <div className={classes.btn__wrapper}>
                                <button onClick={handleSignup}>Register with DIBIZ</button>
                                <div className={classes.login}>
                                    Existing Customer?
                                    <span onClick={handleSignup}>Login from here</span>
                                </div>
                            </div>
                        )
                    }
                    <Link to="/buyers">
                        <i className="material-icons-outlined">
                            east
                        </i>
                        Learn more
                    </Link>
                    {/* <button>Register with DIBIZ</button> */}
                </div>
            </div>
            <div className={classes.section_features}>
                <h3 className={classes.title_features}>Our Features</h3>
                <div className={classes.features_wrapper}>
                    <div className={classes.img_block}>
                        <div className={classes.img_wrapper}>
                            <img src="https://ik.imagekit.io/ofb/dbz/Path_816_2x_RUnY3QPVF.png" alt="Images" />
                        </div>
                        <p>Wide range of products and suppliers</p>
                    </div>
                    <div className={classes.img_block}>
                        <div className={classes.img_wrapper}>
                            <img src="https://ik.imagekit.io/ofb/dbz/Information_2x_za9HHidZy7.png" alt="Images" />
                        </div>
                        <p>Transparent product information</p>
                    </div>
                    <div className={classes.img_block}>
                        <div className={classes.img_wrapper}>
                            <img src="https://ik.imagekit.io/ofb/dbz/verify_2x_Vhlku30Lcu.png" alt="Images" />
                        </div>
                        <p>Verified Suppliers and Buyers</p>
                    </div>
                    <div className={classes.img_block}>
                        <div className={classes.img_wrapper}>
                            <img src="https://ik.imagekit.io/ofb/dbz/negotiation_2x_66W81kKO9.png" alt="Images" />
                        </div>
                        <p>Request and negotiate deals</p>
                    </div>
                    <div className={classes.img_block}>
                        <div className={classes.img_wrapper}>
                            <img src="https://ik.imagekit.io/ofb/dbz/Group_328_2x_hIFmZNc1O.png" alt="Images" />
                        </div>
                        <p>Find your favourite Suppliers</p>
                    </div>
                    <div className={classes.img_block}>
                        <div className={classes.img_wrapper}>
                            <img src="https://ik.imagekit.io/ofb/dbz/partner_Location_2x_sZkGlU_VhE.png" alt="Images" />
                        </div>
                        <p>Find a new partner in your location</p>
                    </div>
                </div>
            </div>
            <div className={classes.section_two}>
                {
                    isMobileState && ( <h3>DIBIZ for <span>{" "} Sellers</span> </h3> )
                }
                <div className={classes.section_two__one}>
                    {
                        !isMobileState && ( <h3>DIBIZ for <span>{" "} Sellers</span> </h3> )
                    }
                    
                    <div className={classes.desc__one}>Scale your market reach with your certified sustainable products</div>
                    <p className={classes.desc__two}>Discover new ways of digital distribution of your certified products. Sell more of your sustainable products for a premium to discerning customers. Receive request for quotes from verified buyers and start a new relationship.</p>

                    {/* <h5 >List your certified products and receive the <span>best value</span> for your product</h5>
                    <div className={classes.desc__three}>Tired of dealing with unverified buyers with no credible information?. With DIBIZ you can now trade with trust!</div> */}
                    <ul className={classes.desc__four}>
                        <li>List unlimited products </li>
                        <li>Opportunity to sell on a global stage </li>
                        <li>Expand your brand presence </li>
                        <li>Right value for your product </li>
                        <li>Brief understanding of the company before dealing with them </li>
                        <li>   Ability to discuss and negotiate within the platform</li>
                    </ul>
                    {
                        loginNameState === "" && (
                            <div className={classes.btn__wrapper}>
                                <button onClick={handleSignup}>Register with DIBIZ</button>
                                <div className={classes.login}>
                                    Existing Customer?
                                    <span onClick={handleSignup}>Login from here</span>
                                </div>
                            </div>
                        )
                    }
                    <Link to="/sellers">
                        <i className="material-icons-outlined">
                            east
                        </i>
                        Learn more
                    </Link>

                </div>
                <div className={classes.section_two__two}>
                    <img src="https://ik.imagekit.io/ofb/dbz/illustration_supplier_owfy8ZMpW9.png" alt="Image" />
                </div>
            </div>
        </div >
    );
};

export default StaticSectionOne;
