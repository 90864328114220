// extracted by mini-css-extract-plugin
export var wrapper = "StaticSectionTwoV2-module--wrapper--ivmvU";
export var title = "StaticSectionTwoV2-module--title--eOZaC";
export var desc = "StaticSectionTwoV2-module--desc--GeikS";
export var section_one = "StaticSectionTwoV2-module--section_one--Nkyx2";
export var section_one__one = "StaticSectionTwoV2-module--section_one__one--fbR9B";
export var section_one__two = "StaticSectionTwoV2-module--section_one__two--Dl0jJ";
export var desc__one = "StaticSectionTwoV2-module--desc__one--5IoOW";
export var desc__two = "StaticSectionTwoV2-module--desc__two--Ia+Oy";
export var desc__three = "StaticSectionTwoV2-module--desc__three--+dPgk";
export var section_two = "StaticSectionTwoV2-module--section_two--PU-e0";
export var section_two__two = "StaticSectionTwoV2-module--section_two__two--OhhNQ";
export var section_two__one = "StaticSectionTwoV2-module--section_two__one--JWLLk";
export var btn__wrapper = "StaticSectionTwoV2-module--btn__wrapper--gDl-R";
export var login = "StaticSectionTwoV2-module--login--GQe1d";
export var desc__four = "StaticSectionTwoV2-module--desc__four--Cyw8U";
export var section_features = "StaticSectionTwoV2-module--section_features---kKSY";
export var title_features = "StaticSectionTwoV2-module--title_features--IXZB4";
export var features_wrapper = "StaticSectionTwoV2-module--features_wrapper--HjbNC";
export var img_block = "StaticSectionTwoV2-module--img_block--PT34B";
export var img_wrapper = "StaticSectionTwoV2-module--img_wrapper--wDn0y";