// extracted by mini-css-extract-plugin
export var wrapper = "Testimonial-module--wrapper--66qwq";
export var title_wrapper = "Testimonial-module--title_wrapper--4bsG+";
export var title = "Testimonial-module--title--+xqtJ";
export var desc = "Testimonial-module--desc--XpG3-";
export var img_wrapper = "Testimonial-module--img_wrapper--TfEpa";
export var img_block = "Testimonial-module--img_block--a0WsB";
export var testimonialBlock = "Testimonial-module--testimonialBlock--QV-Jw";
export var testimonial = "Testimonial-module--testimonial--Twjou";
export var tImage = "Testimonial-module--tImage--QmaKC";
export var author = "Testimonial-module--author--G-0ko";