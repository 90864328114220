import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import * as classes from "./StaticSectionThree.module.css";


const StaticSectionThree = () => {


  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.title_wrapper}>
        <h3 className={classes.title}>Certifications</h3>
      </div>
      <div className={classes.img_wrapper}>
        <div className={classes.img_block}>
          <img src="https://ik.imagekit.io/ofb/dbz/Group40_2x_B4JaATpc6y_.png" alt="Images" />
          {/* <p>Palm Oil Products</p> */}
        </div>
        {/* <div className={classes.img_block}>
          <img src="https://ik.imagekit.io/ofb/dbz/k5-Castor-Oil_2x_g3sN9xgl8.png?updatedAt=1630068999838" alt="Images" />
          <p>Oleo and Derivatives</p>
        </div>
        <div className={classes.img_block}>
          <img src="https://ik.imagekit.io/ofb/dbz/Biodiesel-pump-pic_2x_haldQ4T1Er.png?updatedAt=1630069028138" alt="Images" />
          <p>Biodiesel Products</p>
        </div> */}
      </div>
    </div>
  );
};

export default StaticSectionThree;
