import React, { useState } from "react";
import { Link } from "gatsby";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  PROJECT_LINK,
  IS_PUBLISHED,
  PREVIEW,
  LINK_DISTRIBUTION
} from "../../../project-config";
import ReviewStarMaker from "../../../functions/ReviewStarMaker.jsx";
import htmldecoder from "../../../functions/htmldecoder";
import { toggleCompare } from "../../AC-Header/Compare.jsx";
import {
  deleteCompareItem,
  toggleCompareAction,
  fetchComparedItemDetails,
  deleteComparedItemsDetails
} from "../../../redux/actions/compareActions";

import { getDistanceBetweenTwoCoords } from "../../../functions/Utilities";

import { I18nContext } from "../../../i18n/index";
import * as classes from "./Styles/StoreCard.module.css";
import { setSelectedStoreToViewOnTheMapAction } from "../../../redux/actions/storesAction";

const StoreCard = props => {
  const dispatch = useDispatch();
  const { langCode } = React.useContext(I18nContext);

  const userLocation = useSelector(
    state => state.userLocationReducer,
    shallowEqual
  );

  const {
    id,
    title,
    desc,
    currency_sign,
    image,
    itemLargeImage,
    price,
    url
  } = props.itemCard;

  const storeProps = props.itemCard.properties;
  const tel = "(705) 722-0333";

  const getHref = (text, type) => {
    if (type == "phone") {
      let num = text.replace(/[^a-zA-Z0-9 ]/g, "");
      return "tel:" + num.replace(/\s/g, "");
    } else if (type == "email") return "mailto:" + text;
    else return null;
  };

  const handleStoreItemLocationIconClicked = () => {
    dispatch(setSelectedStoreToViewOnTheMapAction(props.itemCard));
  };

  const getDistance = props => {
    let storeLat,
      storeLng,
      lat,
      lng = null;

    if (props.lat && props.lng && userLocation.lat && userLocation.lng) {
      storeLat = parseFloat(props.lat);
      storeLng = parseFloat(props.lng);
      lat = userLocation.lat;
      lng = userLocation.lng;
    }

    if (storeLat != null && storeLng != null && lat != null && lng != null)
      return (
        getDistanceBetweenTwoCoords(storeLat, storeLng, lat, lng).toFixed() +
        " Km"
      );
  };

  console.info("Store Card", props.itemCard);
  console.info("Store Props", storeProps);

  let imageUrl =
    "https://ik.imagekit.io/dbz" +
    "/" +
    itemLargeImage;
  let storeName = title.replace(/ /g, "-").toLowerCase();
  return (
    <div className={classes.wrapper}>
      <div className={classes.imageWrapper}>
        <img
          src={imageUrl}
          className="img-responsive"
          alt={`${title} Image`}
        />
        {/* {imageUrl} ||| {itemLargeImage} */}
        <div className={classes.flex}>
          <span className={classes.title} title={title}>
            {title}
          </span>
        </div>
      </div>
      <div className={classes.overlay}>
        <div className={classes.flex}>
          <span className={classes.title} title={title}>
            {title}
          </span>
          {/* {userLocation.lat && userLocation.lng && (
            <span className={classes.distance}>{getDistance(storeProps)}</span>
          )} */}
        </div>

        {/* {storeProps.LineAddress1 ? (
          <span className={classes.addressSpan} title={storeProps.LineAddress1}>
            {storeProps.LineAddress1}
          </span>
        ) : (
          <span></span>
        )} */}

        {/* <span className={classes.addressSpan}>
          <b>{storeProps.City ? storeProps.City + ", " : null}</b>
          <b>{storeProps.ProvinceAbv ? storeProps.ProvinceAbv + " " : null}</b>
          <b>{storeProps.PostalCode ? storeProps.PostalCode : null}</b>
        </span> */}
        {/* {props.renderedBy === "sellers-page" && (
          <div
            onClick={handleStoreItemLocationIconClicked}
            className={classes.storeItemLocationIcon}
          >
            <i className="material-icons">location_on</i> Show on the map
          </div>
        )} */}
        <p className={classes.desc}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className={classes.withIconWrapper}>
        <span className={classes.withIcon}>
          {storeProps.Phone ? (
            <>
              
              <a
                className={classes.phone}
                href={getHref(storeProps.Phone, "phone")}
              >
                <i className="material-icons">phone</i>
                {/* {storeProps.Phone} */}
              </a>
            </>
          ) : null}
        </span>
        <span className={classes.withIcon}>
          {storeProps.Email ? (
            <>
              
              <a
                className={classes.phone}
                href={getHref(storeProps.Email, "email")}
              >
                <i className="material-icons">email</i>
                {/* {storeProps.Email} */}
              </a>
            </>
          ) : null}
        </span>
        </div>
        <button className={classes.viewDetails}>
          <Link
            to={
              langCode !== "en"
                ? "/" + langCode + "/stores/" + storeName
                : "/stores/" + storeName
            }
          >
            View Details
          </Link>
        </button>
      </div>
    </div>
  );
};

export default StoreCard;
