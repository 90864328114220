import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "gatsby";

import Grid from "@material-ui/core/Grid";

import * as classes from "./FeaturedCategories.module.css";


const FeaturedCategories = () => {


  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const storeItemsAreLoading = useSelector(
    state => state.storeReducer.loading,
    shallowEqual
  );

  const storesState = useSelector(
    state => state.storeReducer.stores,
    shallowEqual
  );

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1600 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 1600, min: 1360 },
      items: 3
    },
    mdDesktop: {
      breakpoint: { max: 1360, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 768, min: 520 },
      items: 1
    },
    xsMobile: {
      breakpoint: { max: 520, min: 0 },
      items: 1
    }
  };

  const renderPlaceholderCards = () => {
    return (
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={false}
        autoPlay={isMobileState ? true : false}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile", "xsMobile"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {Array(3)
          .fill(0, 0, 6)
          .map((v, i) => (
            <Grid key={i} item className="item-card-item" xs={12}>
              <div
                className="placeholder-item-card-wrapper"
                style={{ boxShadow: "0px 0px 1px 0px #c8c8c8" }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    height: "400px"
                  }}
                ></div>
                <div>
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "40px"
                    }}
                  ></div>
                </div>
              </div>
            </Grid>
          ))}
      </Carousel>
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.title_wrapper}>
        <h3 className={classes.title}>Featured Categories</h3>
      </div>
      {!storeItemsAreLoading && storesState ? (
        <Carousel
          swipeable={isMobileState ? true : false}
          draggable={isMobileState ? true : false}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={false}
          autoPlay={isMobileState ? true : false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["mobile", "xsMobile"]}
          // deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style custom-dot-list-style-new"
          itemClass="carousel-item-padding-40-px"
          renderButtonGroupOutside={true}
        >
          <Link to="/palm-oil/palm-oil-products/" className={classes.img_block}>
            <img src="https://ik.imagekit.io/ofb/dbz/81491583-oil-palm-fruit-ripe-whole-products-food-bulb-bottle_2x_zzLEmqP8c.png?updatedAt=1630069987224" alt="Images" />
            <p>Palm Oil Products</p>
          </Link>
          <Link to="/palm-oil/oleo-and-derivatives/" className={classes.img_block}>
            <img src="https://ik.imagekit.io/ofb/dbz/k5-Castor-Oil_2x_g3sN9xgl8.png?updatedAt=1630068999838" alt="Images" />
            <p>Oleo and Derivatives</p>
          </Link>
          <Link to="/palm-oil/biodiesel-products/" className={classes.img_block}>
            <img src="https://ik.imagekit.io/ofb/dbz/Biodiesel-pump-pic_2x_haldQ4T1Er.png?updatedAt=1630069028138" alt="Images" />
            <p>Biodiesel Products</p>
          </Link>
        </Carousel>
      )
        : (
          renderPlaceholderCards()
        )}
    </div>
    // </div>
  );
};

export default FeaturedCategories;
